import React, { Component } from 'react';
import authService from './api-authorization/AuthorizeService'

export class FetchData extends Component {
    static displayName = FetchData.name;

    constructor(props) {
        super(props);
        this.state = { forecasts: [], loading: true };
    }

    componentDidMount() {
        this.populateData();
    }

    static renderTable(forecasts) {
        return (
            <table className='table table-striped' aria-labelledby="tabelLabel">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Def</th>
                        <th>lang</th>
                        <th>Resource</th>
                    </tr>
                </thead>
                <tbody>
                    {forecasts.map(forecast =>
                        <tr key={forecast.lnnId}>
                            <td>{forecast.lnnId}</td>
                            <td>{forecast.lnnName}</td>
                            <td>{forecast.lnnDef}</td>
                            <td>{forecast.lnnLang}</td>
                            <td>{forecast.lnnResFile}</td>
                        </tr>
                    )}
                </tbody>
            </table>
        );
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : FetchData.renderTable(this.state.forecasts);

        return (
            <div>
                <h1 id="tabelLabel" >Languages</h1>
                <p>This component demonstrates fetching data from the server.</p>
                {contents}
            </div>
        );
    }

    async populateData() {
        const token = await authService.getAccessToken();
        const response = await fetch('msv1/languages', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        this.setState({ forecasts: data, loading: false });
    }
}
