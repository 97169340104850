import React, { Component } from 'react';

export class Close extends Component {
    static displayName = Close.name;

    constructor(props) {
        super(props);
        this.state = { currentCount: 0 };
        this.incrementClose = this.incrementClose.bind(this);
    }

    incrementClose() {
        this.setState({
            currentCount: this.state.currentCount + 1
        });
    }

    render() {
        return (
            <div>
                ttttt
                <script>
                   window.open('','_self').close()
                </script>
            </div>
        );
    }
}